@tailwind base;
@tailwind components;
@tailwind utilities;



:root {
  --cubic-bezier: cubic-bezier(0.5, 0.2, 0.2, 1.3);
}
html {
  scrollbar-width: 10px;
}

body {
  scrollbar-color: #888 #f1f1f1; /* Thumb and track color */
}
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Border radius of the scrollbar thumb */
}