.carousel_main {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.Carousel {
  box-shadow: 0px 0px 7px #666;
  position: relative;
  overflow: hidden;
}

.slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slide_hidden {
  display: none;
}

.arrow {
  position: absolute;
  right: 4px;
  display: flex;
  gap: 10px;
  color: #000;
  bottom: 0;
}
.image_description {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20%;
  background-color: rgba(38, 38, 38, 0.6);
  color: #fff;
}
.image_description_content_hidden {
  display: none;
}
.index {
  display: flex;
}
.index_text {
  background-color: #428898;
}
.mobile_swiper {
  padding: 1rem;
  width: 100vw;
}
/* @media screen and (min-width: 800px) {
  .carousel_main {
    height: 90vh;
  }
} */
